import React, { useContext, useState } from "react";
import { Link, navigate } from "gatsby";
import { doc, setDoc } from "firebase/firestore";
import styled from "styled-components";

import { FirebaseContext } from "../components/Firebase";
import Seo from "../components/seo";
import { ContentBg, ContentBox, Button } from "../components/ui";

const TextArea = styled.textarea`
  display: block;
  width: 50%;
  height: 4rem;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: none;
  &:focus,
  &:active {
    border: 1px solid rebeccapurple;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const Contact = () => {
  const { firebase, user } = useContext(FirebaseContext);
  const [sent, setSent] = useState(false);

  const now = Date.now().toLocaleString();
  const sendForm = async e => {
    e.preventDefault();
    const message = e.target.message.value.trim();
    const utc = Date.now().toString();
    const { userId, name, email } = user;

    const result = await setDoc(doc(firebase.db, "feedback", utc), {
      userId,
      name,
      email,
      message,
      timestamp: Date(),
      utc,
    });
    setSent(true);
  };
  return (
    <ContentBg src="/static/7324d5a7e74a2d836b35f4f9f3f163cd/07f1b/wp-content-uploads-2016-06-for-fathers-day-liannes-teachers.jpg">
      <ContentBox>
        <Seo title="Contact" />
        <h1>Contact</h1>
        <form onSubmit={sendForm}>
          {!user ? (
            <p>
              Een berichtje is altijd leuk! Iets minder leuk zijn die vervelende
              "bots" die dit soort contact formuliertjes misbruiken voor spam...
              vandaar graag eerst even <Link to="/login">Inloggen</Link> met je
              unieke MarkKater.nl account... of er{" "}
              <Link to="/register">snel even eentje aanmaken</Link>, mocht je
              nog geen account hebben -- thanks! :)
            </p>
          ) : (
            <>
              <p>
                <label htmlFor="name">Afzender:</label> {user.name}
              </p>
              <TextArea
                name="message"
                placeholder="Alvast bedankt voor je berichtje! :)"
              ></TextArea>
              {!sent ? <Button>Versturen</Button> : <p>Verzonden!</p>}
            </>
          )}
        </form>
      </ContentBox>
    </ContentBg>
  );
};

export default Contact;
